<template>
  <div class="page-home">
    <div class="home-header">
      <van-notice-bar
        speed="80"
        :text="title"
        v-if="title && title.length > 14"
      />
      <van-notice-bar
        class="home-title"
        speed="80"
        :text="title ? title : '培训信息化微平台'"
        v-else
      />
    </div>
    <div class="home-body">
      <div class="home-tooltip">
        <div class="text-left">
          <span class="text-week">{{ todayDay }}</span>
          <span class="text-date">{{ todayDate }}</span>
        </div>
        <div class="text-right">
          <img class="text-icon" :src="weatherImg" />
          <span class="text-weather">{{ weather }}</span>
          <span class="text-temp">{{ celcius }}</span>
        </div>
      </div>
      <div class="home-main">
        <div class="home-main-mask">
          <div class="home-main-logo"></div>
          <div class="home-main-title">欢迎你，新学员</div>
          <div>
            <div
              class="home-main-btn"
              @click="navTap('detail', 1, { id: welcomeId })"
            >
              查看欢迎辞
            </div>
            <div class="home-main-logout" @click="logout">注销</div>
          </div>
        </div>
      </div>
      <div class="home-part home-plan" @click="navTap('plan')">
        <div class="home-part-content">
          <div class="home-part-title">日程安排</div>
          <div class="home-part-text">了解本班课程详情</div>
        </div>
        <svg
          class="home-part-icon"
          width="64px"
          height="64px"
          viewBox="0 0 64 64"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>课程安排1</title>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="首页-1"
              transform="translate(-276.000000, -388.000000)"
              fill-rule="nonzero"
            >
              <g id="课程安排1" transform="translate(276.000000, 388.000000)">
                <rect
                  id="Rectangle"
                  fill="#000000"
                  opacity="0"
                  x="0"
                  y="0"
                  width="64"
                  height="64"
                ></rect>
                <path
                  d="M58.4070312,43.3069934 C58.4070312,52.8024711 46.4858266,55.1301563 31.7820312,55.1301563 C17.0782359,55.1301563 5.15703125,52.8024711 5.15703125,43.3069934 C5.15703125,33.8115157 17.0782359,20.7395313 31.7820312,20.7395313 C46.4858266,20.7395313 58.4070312,33.8115157 58.4070312,43.3069934 Z"
                  id="Path"
                  fill="#FFFFFF"
                  opacity="0.235723586"
                ></path>
                <path
                  d="M49.4480469,12.7916406 L15.6115625,12.7916406 C14.2130469,12.7916406 13.0803125,13.924375 13.0803125,15.3228906 L13.0803125,42.7046875 C13.0803125,44.1032031 14.2130469,45.2359375 15.6115625,45.2359375 L49.4480469,45.2359375 C50.8465625,45.2359375 51.9792969,44.1032031 51.9792969,42.7046875 L51.9792969,15.3228906 C51.9792969,13.924375 50.8465625,12.7916406 49.4480469,12.7916406 Z"
                  id="Path"
                  fill="#42AEFC"
                ></path>
                <path
                  d="M38.0510938,44.3816406 C38.3042188,44.755 38.0321094,45.26125 37.5828125,45.26125 L26.1921875,45.26125 C25.7428906,45.26125 25.4707813,44.755 25.7239063,44.3816406 C25.9770313,44.0082812 25.7049219,43.5020313 25.255625,43.5020313 L11.0426563,43.5020313 L11.0426563,44.5778125 C11.0426563,46.2547656 12.4032031,47.6089844 14.0738281,47.6089844 L49.6948438,47.6089844 C51.3717969,47.6089844 52.7260156,46.2484375 52.7260156,44.5778125 L52.7260156,43.5020313 L38.5257031,43.5020313 C38.0700781,43.4957031 37.8042969,44.0019531 38.0510938,44.3816406 Z"
                  id="Path"
                  fill="#0091F1"
                ></path>
                <path
                  d="M48.0685156,41.0150781 L16.9910937,41.0150781 C16.295,41.0150781 15.7254687,40.4455469 15.7254687,39.7494531 L15.7254687,17.4428125 C15.7254687,16.7467187 16.295,16.1771875 16.9910937,16.1771875 L48.0685156,16.1771875 C48.7646094,16.1771875 49.3341406,16.7467187 49.3341406,17.4428125 L49.3341406,39.7557812 C49.3341406,40.451875 48.7646094,41.0150781 48.0685156,41.0150781 L48.0685156,41.0150781 Z"
                  id="Path"
                  fill="#BBF2FF"
                ></path>
                <path
                  d="M26.0213281,28.5929688 C26.0213281,32.1857536 28.9338557,35.0982812 32.5266406,35.0982812 C36.1194255,35.0982812 39.0319531,32.1857536 39.0319531,28.5929688 C39.0319531,25.0001839 36.1194255,22.0876563 32.5266406,22.0876563 C28.9338557,22.0876563 26.0213281,25.0001839 26.0213281,28.5929688 Z"
                  id="Path"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M32.1785938,25.5175 L35.1464844,27.9475 C35.5514844,28.2828906 35.5514844,28.9030469 35.1464844,29.2384375 L32.1785938,31.6684375 C31.634375,32.1114063 30.8180469,31.7253906 30.8180469,31.0229688 L30.8180469,26.1629688 C30.8180469,25.4605469 31.634375,25.0745313 32.1785938,25.5175 L32.1785938,25.5175 Z"
                  id="Path"
                  fill="#7BD721"
                ></path>
                <path
                  d="M55.2319531,19.7146094 L42.8035156,19.7146094 C41.405,19.7146094 40.2722656,18.581875 40.2722656,17.1833594 L40.2722656,9.83007812 C40.2722656,8.4315625 41.405,7.29882812 42.8035156,7.29882812 L55.2319531,7.29882812 C56.6304687,7.29882812 57.7632031,8.4315625 57.7632031,9.83007812 L57.7632031,17.1833594 C57.7632031,18.581875 56.6304687,19.7146094 55.2319531,19.7146094 Z"
                  id="Path"
                  fill="#FFD500"
                ></path>
                <path
                  d="M53.25125,11.9689844 L44.7842187,11.9689844 C44.3855469,11.9689844 44.0628125,11.64625 44.0628125,11.2475781 C44.0628125,10.8489062 44.3855469,10.5261719 44.7842187,10.5261719 L53.25125,10.5261719 C53.6499219,10.5261719 53.9726562,10.8489062 53.9726562,11.2475781 C53.9663281,11.64625 53.6499219,11.9689844 53.25125,11.9689844 L53.25125,11.9689844 Z M51.1566406,16.4809375 L44.6196875,16.4809375 C44.2210156,16.4809375 43.8982812,16.1582031 43.8982812,15.7595312 C43.8982812,15.3608594 44.2210156,15.038125 44.6196875,15.038125 L51.1566406,15.038125 C51.5553125,15.038125 51.8780469,15.3608594 51.8780469,15.7595312 C51.8780469,16.1582031 51.5553125,16.4809375 51.1566406,16.4809375 Z"
                  id="Shape"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M40.2722656,17.4554687 L42.9553906,19.7146094 L41.0189844,20.7271094 C40.4747656,21.011875 39.835625,20.5625781 39.9178906,19.94875 L40.2722656,17.4554687 Z"
                  id="Path"
                  fill="#FFD500"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div
        class="home-part home-student"
        @click="navTap('student')"
        v-if="showContacts"
      >
        <div class="home-part-content">
          <div class="home-part-title">学员名单</div>
          <div class="home-part-text">快来看看你的同学</div>
        </div>
        <svg
          class="home-part-icon"
          width="64px"
          height="64px"
          viewBox="0 0 64 64"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>学员名单1</title>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="首页-1" transform="translate(-277.000000, -485.000000)">
              <g id="学员名单1" transform="translate(277.000000, 485.000000)">
                <path
                  d="M57.2769531,41.4085551 C57.2769531,50.8181054 45.3557485,53.1247267 30.6519531,53.1247267 C15.9481577,53.1247267 4.02695313,50.8181054 4.02695313,41.4085551 C4.02695313,31.9990047 15.9481577,19.0453125 30.6519531,19.0453125 C45.3557485,19.0453125 57.2769531,31.9990047 57.2769531,41.4085551 Z"
                  id="Path"
                  fill-opacity="0.257593969"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M32.1640625,9 L28.390625,9 C27.6494141,9 27.0429688,9.60644531 27.0429688,10.3476562 L27.0429688,14.1210938 C27.0429688,14.8623047 27.6494141,15.46875 28.390625,15.46875 L32.1640625,15.46875 C32.9052734,15.46875 33.5117188,14.8623047 33.5117188,14.1210938 L33.5117188,10.3476562 C33.5117188,9.60644531 32.9052734,9 32.1640625,9 Z"
                  id="Path"
                  fill="#CD3834"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M49.4410156,45.3867188 L11.1136719,45.3867188 C9.62451172,45.3867188 8.41835937,44.1805664 8.41835937,42.6914062 L8.41835937,16.4121094 C8.41835937,14.9229492 9.62451172,13.7167969 11.1136719,13.7167969 L49.4410156,13.7167969 C50.9301758,13.7167969 52.1363281,14.9229492 52.1363281,16.4121094 L52.1363281,42.6914062 C52.1363281,44.1805664 50.9301758,45.3867188 49.4410156,45.3867188 Z"
                  id="Path"
                  fill="#FC5858"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M34.0036133,41.8828125 L13.4788086,41.8828125 C12.7375977,41.8828125 12.1311523,41.2763672 12.1311523,40.5351562 L12.1311523,18.5683594 C12.1311523,17.8271484 12.7375977,17.2207031 13.4788086,17.2207031 L34.0103516,17.2207031 C34.7515625,17.2207031 35.3580078,17.8271484 35.3580078,18.5683594 L35.3580078,40.5351562 C35.3512695,41.2763672 34.7515625,41.8828125 34.0036133,41.8828125 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M15.5878906,29.5517578 C15.5878906,34.0547123 19.2382565,37.7050781 23.7412109,37.7050781 C28.2441654,37.7050781 31.8945312,34.0547123 31.8945312,29.5517578 C31.8945312,25.0488033 28.2441654,21.3984375 23.7412109,21.3984375 C19.2382565,21.3984375 15.5878906,25.0488033 15.5878906,29.5517578 L15.5878906,29.5517578 Z"
                  id="Path"
                  fill="#FFCA82"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M18.9570312,26.9912109 C18.9570312,27.7355009 19.5603975,28.3388672 20.3046875,28.3388672 C21.0489775,28.3388672 21.6523438,27.7355009 21.6523438,26.9912109 C21.6523438,26.2469209 21.0489775,25.6435547 20.3046875,25.6435547 C19.5603975,25.6435547 18.9570312,26.2469209 18.9570312,26.9912109 L18.9570312,26.9912109 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M25.8974609,26.9912109 C25.8974609,27.7355009 26.5008272,28.3388672 27.2451172,28.3388672 C27.9894072,28.3388672 28.5927734,27.7355009 28.5927734,26.9912109 C28.5927734,26.2469209 27.9894072,25.6435547 27.2451172,25.6435547 C26.5008272,25.6435547 25.8974609,26.2469209 25.8974609,26.9912109 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M28.2086914,31.472168 C28.2086914,33.8507812 26.1939453,35.784668 23.7075195,35.784668 C21.2210938,35.784668 19.2063477,33.8507812 19.2063477,31.472168 C19.2063477,29.0935547 28.2086914,29.0935547 28.2086914,31.472168 L28.2086914,31.472168 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M20.3316406,34.3291992 C21.1537109,35.2253906 22.3598633,35.784668 23.7075195,35.784668 C25.0888672,35.784668 26.3219727,35.1916992 27.1507813,34.2550781 C26.4365234,33.4869141 25.1966797,32.981543 23.7749023,32.981543 C22.3126953,32.9748047 21.0324219,33.5206055 20.3316406,34.3291992 Z"
                  id="Path"
                  fill="#EF8686"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M47.4599609,23.5546875 L39.7109375,23.5546875 C38.8888672,23.5546875 38.2285156,22.8943359 38.2285156,22.0722656 C38.2285156,21.2501953 38.8888672,20.5898438 39.7109375,20.5898438 L47.4599609,20.5898438 C48.2820313,20.5898438 48.9423828,21.2501953 48.9423828,22.0722656 C48.9423828,22.8943359 48.2820313,23.5546875 47.4599609,23.5546875 Z M47.4599609,31.7753906 L39.7109375,31.7753906 C38.8888672,31.7753906 38.2285156,31.1150391 38.2285156,30.2929688 C38.2285156,29.4708984 38.8888672,28.8105469 39.7109375,28.8105469 L47.4599609,28.8105469 C48.2820313,28.8105469 48.9423828,29.4708984 48.9423828,30.2929688 C48.9423828,31.1150391 48.2820313,31.7753906 47.4599609,31.7753906 Z"
                  id="Shape"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <rect id="Rectangle" x="0" y="0" width="64" height="64"></rect>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="home-part home-guide">
        <div class="home-part-content">
          <div class="home-part-title">学习指南</div>
          <div class="home-part-text">开学必看</div>
          <div
            class="home-arrow"
            :class="showGuide ? 'home-arrow-down' : ''"
            @click.stop="showGuide = !showGuide"
          ></div>
        </div>
      </div>
      <div class="home-list-box" :class="showGuide ? 'home-list-box-tap' : ''">
        <div
          class="home-list"
          v-for="(item, index) in guideList"
          :key="index"
          @click="navTap(item.nav, 0, item)"
        >
          <div class="list-left">
            <img :src="item.img" />
          </div>
          <div class="list-right">
            <div class="list-title">{{ item.title }}</div>
            <div class="list-date">{{ item.date }}</div>
          </div>
        </div>
        <div v-if="guideList.length === 0">
          <van-empty image="search" description="暂无数据" />
        </div>
      </div>
      <div class="home-part home-scenery">
        <div class="home-part-content">
          <div class="home-part-title">珞珈风华</div>
          <div class="home-part-text">武汉大学风光</div>
          <div
            class="home-arrow"
            :class="showScenery ? 'home-arrow-down' : ''"
            @click.stop="showScenery = !showScenery"
          ></div>
        </div>
      </div>
      <div
        class="home-list-box"
        :class="showScenery ? 'home-list-box-tap' : ''"
      >
        <div
          class="home-list"
          v-for="(item, index) in sceneryList"
          :key="index"
          @click="navTap(item.nav, 2, item)"
        >
          <div class="list-left">
            <img :src="item.img" />
          </div>
          <div class="list-right">
            <div class="list-title">{{ item.title }}</div>
            <div class="list-date">{{ item.date }}</div>
          </div>
        </div>
        <div v-if="sceneryList.length === 0">
          <van-empty image="search" description="暂无数据" />
        </div>
      </div>

      <div class="home-part home-learn" v-if="showLearn">
        <div class="home-part-content">
          <div class="home-part-title">学习掠影</div>
          <div class="home-part-text">记录求学的瞬间</div>
          <div
            class="home-arrow"
            :class="showLearnList ? 'home-arrow-down' : ''"
            @click.stop="showLearnList = !showLearnList"
          ></div>
        </div>
      </div>
      <div
        class="home-list-box"
        :class="showLearnList ? 'home-list-box-tap' : ''"
        v-if="showLearn"
      >
        <div
          class="home-list"
          v-for="(item, index) in learnList"
          :key="index"
          @click="navTap(item.nav, 4, item)"
        >
          <div class="list-left">
            <img :src="item.img" />
          </div>
          <div class="list-right">
            <div class="list-title">{{ item.title }}</div>
            <div class="list-date">{{ item.date }}</div>
          </div>
        </div>
        <div v-if="learnList.length === 0">
          <van-empty image="search" description="暂无数据" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tianqiapi, newsList, getWelcomeId } from "@/service";
import { Dialog } from "vant";
import { baseUrl } from "@/service";
export default {
  name: "home",
  components: {},
  data() {
    return {
      title: "培训信息化微平台",
      showGuide: false,
      showScenery: false,
      showLearnList: false,
      todayDay: "",
      todayDate: "",
      welcomeId: "", // 迎新欢迎词文章id
      weather: "", // 天气信息
      weatherImg: "", // 天气信息图片
      celcius: "", // 摄氏度
      guideList: [],
      sceneryList: [],
      learnList: [], // 学习掠影
      showContacts: false, // 是否显示通讯录
      showLearn: false, // 是否显示学习掠影
    };
  },
  created() {
    this.getHeaderInfo();
    this.getNewLists();
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      try {
        let userInfo = localStorage.getItem("userInfo");
        userInfo = JSON.parse(userInfo);
        this.showContacts = userInfo.showContacts ?? false;
        this.showLearn = userInfo.showLearn ?? false;
      } catch (e) {
        console.error(e);
      }
    },
    // 载入头部信息
    async getHeaderInfo() {
      // 载入班级名称
      this.title = JSON.parse(localStorage.getItem("userInfo")).fullName;
      // window.document.title = this.title
      // 载入头部信息
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      this.todayDate = new Date().getMonth() + 1 + "-" + new Date().getDate();
      this.todayDay = weeks[new Date().getDay()];
      // 信息缓存
      let weatherInfo = sessionStorage.getItem("weatherInfo");
      if (!weatherInfo) {
        let { wea, wea_img, tem2, tem1 } = await tianqiapi();
        this.weather = wea;
        this.weatherImg = require(`@/assets/weather/${wea_img}.png`);
        this.celcius = `${tem2}-${tem1}℃`;
        let info = {
          wea: wea,
          wea_img: wea_img,
          tem1: tem1,
          tem2: tem2,
        };
        sessionStorage.setItem("weatherInfo", JSON.stringify(info));
      } else {
        let { wea, wea_img, tem2, tem1 } = JSON.parse(weatherInfo);
        this.weather = wea;
        this.weatherImg = require(`@/assets/weather/${wea_img}.png`);
        this.celcius = `${tem2}-${tem1}℃`;
      }
    },
    // 获取新闻列表
    async getNewLists() {
      const resultGuide = await newsList(localStorage.getItem("SID"), "A10003");
      this.guideList = resultGuide.data.map((i) => ({
        id: i.id,
        img: baseUrl + i.image,
        title: i.title,
        date: i.updateDate,
        nav: "detail",
        href: i.href,
        source: i.source,
      }));

      const resultScenery = await newsList(
        localStorage.getItem("SID"),
        "A10004"
      );
      this.sceneryList = resultScenery.data.map((i) => ({
        id: i.id,
        img: baseUrl + i.image,
        title: i.title,
        date: i.updateDate,
        nav: "detail",
        href: i.href,
        source: i.source,
      }));

      const resultLearn = await newsList(localStorage.getItem("SID"), "A10005");
      this.learnList = resultLearn.data.map((i) => {
        const image = i?.image.split("|")[0] ?? "";
        return {
          id: i.id,
          img: baseUrl + image,
          title: i.title,
          date: i.updateDate,
          nav: "detail",
          href: i.href,
          source: i.source,
        };
      });
      // 欢迎词
      let res = await getWelcomeId(localStorage.getItem("SID"));
      this.welcomeId = res.data;
    },
    readAssignment(val) {
      val.read = true;
    },
    navTap(nav, type, item) {
      if (!!item?.href && item?.source === "1") {
        item.href ? (window.location.href = item.href) : "";
      } else {
        this.$router.push({
          path: "/" + nav,
          query: {
            type: type,
            id: item?.id,
          },
        });
      }
    },
    navSurface() {
      this.$router.push({
        path: "/home/surface",
      });
    },
    // 注销函数
    logout() {
      Dialog.confirm({
        title: "注销",
        message: "是否注销账户？",
      }).then(() => {
        localStorage.setItem("openId", "");
        localStorage.setItem("UUID", "");
        localStorage.setItem("SID", "");
        this.$router.replace({
          path: "/login",
        });
      });
    },
  },
};
</script>

<style lang="less">
.page-home {
  box-sizing: border-box;
  font-size: 14px;
  .home-header {
    width: 100%;
    box-shadow: 0px 2px 7px 0px rgba(14, 19, 55, 0.08);
    position: relative;
    z-index: 1;
    line-height: 22px;
    text-align: center;
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
    padding: 0 15%;
    max-width: 70%;
    height: 46px;
    overflow: hidden;
    .van-notice-bar {
      height: 100%;
      background-color: #fff;
      color: #333;
      font-size: 16px;
    }
    .home-title {
      .van-notice-bar__wrap {
        justify-content: center;
      }
    }
  }
  .home-tooltip {
    display: flex;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #535353;
    .text-left {
      flex: 1;
    }
    .text-right {
      flex: 1;
    }
    .text-week {
      padding-left: 16px;
    }
    .text-date {
      padding-left: 13px;
      color: #bebebe;
    }
    .text-icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 7px;
      margin-top: -4px;
      vertical-align: middle;
    }
    .text-weather {
      padding-right: 13px;
    }
    .text-temp {
      padding-right: 16px;
    }
  }
  .home-body {
    padding: 0 12px 12px;
    .home-main {
      width: 100%;
      height: 170px;
      background: url(../static/whdx1.png);
      background-size: 100% 100%;
      border-radius: 12px;
      margin-bottom: 16px;
      &-mask {
        width: 100%;
        height: 164px;
        background: linear-gradient(
          180deg,
          #71b4fb 0%,
          rgba(216, 216, 216, 0) 100%
        );
        border-radius: 12px 12px 0px 0px;
      }
      &-logo {
        width: 152px;
        height: 26px;
        background: url(../static/login-logo.png) 0 0 no-repeat;
        background-size: 100% 100%;
        margin-left: 11px;
        border-top: 11px solid transparent;
      }
      &-title {
        font-size: 36px;
        font-family: NotoSerifSC-Bold, NotoSerifSC;
        font-weight: bold;
        color: #ffffff;
        line-height: 51px;
        height: 51px;
        overflow: hidden;
        text-shadow: 0px 1px 3px rgba(51, 66, 111, 0.38);
        // width: 252px;
        // margin: 5px auto 0;
        margin-top: 5px;
        text-align: center;
      }
      &-btn {
        width: 97px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(270deg, #458cff 0%, #06a9f4 100%);
        border-radius: 14px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-top: 32px;
        margin-left: 21px;
        float: left;
      }
      &-logout {
        width: 67px;
        height: 25px;
        line-height: 26px;
        background: linear-gradient(270deg, #458cff 0%, #06a9f4 100%);
        border-radius: 14px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        float: right;
        margin-top: 35px;
        margin-right: 21px;
      }
    }
    .home-part {
      width: 100%;
      height: 76px;
      border-radius: 8px;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      &-content {
        flex: 1;
      }
      &-icon {
        width: 64px;
        margin: 8px 20px 0 0;
      }
      &-title {
        font-size: 21px;
        font-weight: 500;
        color: #ffffff;
        padding: 14px 0 0 16px;
      }
      &-text {
        font-size: 14px;
        color: #ffffff;
        opacity: 0.57;
        padding: 2px 0 0 16px;
      }
      .home-arrow {
        width: 20px;
        height: 20px;
        background: url("~@/assets/arrow-down.png") 0 0 no-repeat;
        background-size: 100% 100%;
        transform: rotate(-90deg);
        transition: 0.3s ease;
        position: absolute;
        right: 28px;
        top: 28px;
        &.home-arrow-down {
          transform: rotate(0);
        }
      }
    }
    .home-plan {
      // background: linear-gradient(-136deg, #83f5ff 0%, #476ef1 100%);
      background: url(../static/plan.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .home-student {
      // background: linear-gradient(-180deg, #ffcf00 0%, #ffb25b 100%);
      background: url(../static/student.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .home-guide {
      // background: linear-gradient(-140deg, #ffbfa6 0%, #f87d48 100%);
      background: url(../static/guide.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .home-learn {
      // background: linear-gradient(-140deg, #ffbfa6 0%, #f87d48 100%);
      background: url(../static/teacher3.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .home-scenery {
      // background: linear-gradient(-136deg, #668ced 0%, #6737dd 100%);
      background: url(../static/scenery.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .home-list-box {
      max-height: 0;
      background: #ffffff;
      box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0 14px;
      transition: max-height 0.5s, margin-bottom 0.5s;
      margin-bottom: 0;
      overflow: hidden;
      &.home-list-box-tap {
        max-height: 1000px;
        margin-bottom: 9px;
      }
      .home-list {
        padding: 18px 0 14px;
        border-bottom: 1px solid #eee;
        display: flex;
        &:nth-last-child(1) {
          border-bottom: 0px;
        }
      }
      .list-left {
        flex: 3;
        img {
          width: 100%;
          height: 72px;
        }
      }
      .list-right {
        flex: 5;
        font-size: 18px;
        .list-title {
          height: 58px;
          color: rgba(0, 0, 0, 0.8);
          padding-left: 14px;
          overflow: hidden;
        }
        .list-date {
          color: #b5b5b5;
          padding-left: 14px;
        }
      }
    }
  }
}
</style>
